import React from "react";
import { Row, Col } from "react-materialize";
// import aboutaimg from "./img/abouta.jpg";
// import waboutaimg from "./img/abouta.webp";
export default class AboutA extends React.Component {
	render() {
		return (
			<section id="sabout" className="abouta">
				<Row>
					<Col s={12} data-aos="fade-up" data-aos-delay="450">
						<p>
							<b>Dr Wojciech Bogusław Moś</b> – historyk, absolwent Uniwersytetu Śląskiego w
							Katowicach. Wieloletni pracownik (kustosz dyplomowany) Muzeum Górnośląskiego w
							Bytomiu. Inspirator i twórca wielu ciekawych, niejednokrotnie nagrodzonych wystaw
							historycznych. Doktoryzował się na Wydziale Nauk Społecznych Uniwersytetu Śląskiego.
							Zajmuje się historią Wojska Polskiego okresu międzywojennego, ze szczególnym
							uwzględnieniem formacji górskich, oraz polską symboliką i falerystyką wojskową. Autor
							wielu publikacji, w tym: <b>Strzelcy Podhalańscy: 1918-1939</b>, Kraków 1989,{" "}
							<b>Wojska górskie w armiach świata</b>, Katowice-Bytom 1994,{" "}
							<b>Polskie szkolnictwo wojskowe 1908-1939: Odznaki-Emblematy-Dokumenty</b>, Kraków
							2007,{" "}
							<b>
								W Garnizonie Bielsko-Biała: 3 Pułk Strzelców Podhalańskich, 21 Pułk Artylerii
								Lekkiej 1918-1939
							</b>
							, Bielsko-Biała 2015.
						</p>
					</Col>
					{/* <Col s={12} l={5} data-aos="fade-left" data-aos-delay="300">
						<img src={this.props.webp ? waboutaimg : aboutaimg} alt="Autor" />
					</Col> */}
				</Row>
			</section>
		);
	}
}

import React from "react";
import { Fgal } from "./Fgal";
import { images } from "./_images";
import { wimages } from "./_wimages";

export class Galeria extends React.Component {
  render() {
    return (
      <section id="sgaleria" className="galeria">
        <h4 data-aos="fade-up" data-aos-delay="200">
          Galeria
        </h4>
        <span data-aos="fade-in" data-aos-delay="400">
          <Fgal img={this.props.webp ? wimages : images} />
        </span>
      </section>
    );
  }
}

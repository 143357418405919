import g1 from "./img/galeria/g-1.jpg";
import g2 from "./img/galeria/g-2.jpg";
import g3 from "./img/galeria/g-3.jpg";
import g4 from "./img/galeria/g-4.jpg";
import g5 from "./img/galeria/g-5.jpg";
import g6 from "./img/galeria/g-6.jpg";
import g7 from "./img/galeria/g-7.jpg";
import g8 from "./img/galeria/g-8.jpg";
import g9 from "./img/galeria/g-9.jpg";
import g10 from "./img/galeria/g-10.jpg";
import g11 from "./img/galeria/g-11.jpg";
import g12 from "./img/galeria/g-12.jpg";
export const images = [
  {
    src: g1,
    width: 202,
    height: 121,
    title: "2 PSP – pułkowa szkoła podoficerska 1936/1937. Siedzą od lewej: ppor. Józef Mikuła, ppor. Władysław Sochański, kpt. Bolesław Jus, kpt. Tadeusz Dyląg, ppłk Stanisław Styrczula (z-ca d-cy pułku),płk Zygmunt Csadek (d-ca pułku), ks. kpl. (kpt.) Roman Kostikow, kpt. Władysław Mróz, por. Franciszek Gacek, por. Tadeusz Ciełowicz, ppor, Jan Stala oraz: st. sierż. Fuhrman, sierż. Królicki, plut. Soroczyński, kpr. Józef Tołcz"
  },
  {
    src: g2,
    width: 171,
    height: 121,
    title: "DKPRez./4 PSP. Obóz w Łopusznej 1937. Wręczenie świadectw ukończenia Kursu przez dowódcę 21 DPG płka Józefa Kustronia, w obecności kadry. Świadectwa podaje por. Bazyli Romanow (1 PSP).Otrzymują trzej prymusi"
  },
  {
    src: g3,
    width: 186,
    height: 121,
    title: "3. PSP – pułkowa szkoła podoficerska, 1937/1938. Siedzą od lewej: 1. plut. Bolesław Sygodziński, 2. ppor. Stanisław Piątek, 3. kpt. Michał Ziemiański, 4. ppłk Józef Giza, 5. kpt. Stefan Cerkaski, 6. st. sierż, Józef Fijak, 7. kpr. Jan Gradek; stoją – z lewej: 1. kpr. Jerzy Witkowski, wyżej: 1. kpr. Piotr Pniak, z prawej: 2. kpr. Emanuel Fichna"
  },
  {
    src: g4,
    width: 112,
    height: 122,
    title: "4 PSP. Powrót z manewrów. Dowódca pułku płk Antoni Własak witany przez delegację mieszkańców w osobach dwóch cieszynianek w regionalnych strojach ludowych. Obok adiutant pułku kpt. Marian Podniesiński, z tyłu na koniu wachm. Józef Skórka. Cieszyn"
  },
  {
    src: g5,
    width: 111,
    height: 121,
    title: "1 PSP – kpr. Franciszek Malik (dowódca drużyny) podczas nauki celowania"
  },
  {
    src: g6,
    width: 186,
    height: 121,
    title: "3. PSP – gen. Wacław Przeździecki, dowódca 21. DPG, podczas rozmowy z uczniami pułkowej szkoły podoficerskiej, ok. 1930 r."
  },
  {
    src: g7,
    width: 185,
    height: 121,
    title: "DKPRez./4 PSP, 1936. Na strzelnicy"
  },
  {
    src: g8,
    width: 100,
    height: 121,
    title: "1 PSP. Obiad żołnierski z okazji święta pułkowego 26 V 1937"
  },
  {
    src: g9,
    width: 99,
    height: 160,
    title: "Płk Franciszek Wagner: dowódca 3 PSP w latach 1922 – 1927, jeden z najwybitniejszych wojskowych działaczy sportowych, propagator narciarstwa i w ogóle sportu w wojsku, związany z SN TS „Wisła”"
  },
  {
    src: g10,
    width: 99,
    height: 160,
    title: "Kpt. Artur Kasprzyk – 12 PP, 23, 21 i 24 PAL jeden z najwybitniejszych polskich narciarzy wojskowych, uczestnik Igrzysk Olimpijskich w Garmisch Partenkirchen w 1936 r., uczestnik III powstania śląskiego, teoretyk i trener narciarski"
  },
  {
    src: g11,
    width: 273,
    height: 163,
    title: "Polski patrol wojskowy wystawiony na Międzynarodowe Zawody Narciarskie FIS. Zakopane 5 – 10 II 1929. Od lewej: strz. Stanisław Skupień 3 PSP, kpr. Jan Pawluszkiewicz 3 PSP, por. Artur Kasprzyk 21 PAP, komendant patrolu, szer. Józef Kuraś 5 Dyon Samochodowy, strz. Józef Hrywniak 1 PSP"
  },
  {
    src: g12,
    width: 272,
    height: 163,
    title: "Narciarze 3. PSP – uczestnicy nieokreślonych zawodów, ok. 1930–1935. Z lewej: 1. sierż. Jacenty Gibes, 2. sierż. Józef Biernat; w środku płk Karol Zagórski i kpt. Ernest Stiller; pozostali nierozpoznani"
  }
];

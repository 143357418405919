import React from "react";
import { Row, Col } from "react-materialize";

export default class Quote extends React.Component {
  render() {
    return (
      <section className="quote">
        <Row>
          <Col s={12}>
            <h4
              data-aos="fade-up"
              data-aos-delay="150"
              className="frag-title two"
            >
              Podczas walk w górach największe znaczenie przywiązywano do
              działań piechoty tj. strzelców
            </h4>
          </Col>
        </Row>
      </section>
    );
  }
}

import React from "react";
import { Row, Col, TextInput, Textarea, Button } from "react-materialize";
import { Map, PhoneCall, Mail } from "react-feather";

export default class Kontakt extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ButtonText: "Wypełnij",
			button_class: "contact-submit",
			name: "",
			email: "",
			email2: "",
			phone: "",
			message: "",
			nameValid: null,
			emailValid: null,
			email2Valid: true,
			phoneValid: null,
			messageValid: null,
			formValid: false,
			sent: false,
		};
	}
	submitHandler = (event) => {
		event.preventDefault();
		this.sendMail(
			"strzelcy",
			this.state.name,
			this.state.email,
			this.state.phone,
			this.state.message
		);
	};
	sendMail(templateId, name, email, phone, message) {
		if (this.state.formValid) {
			window.emailjs
				.send("smtp_server", templateId, {
					name,
					email,
					phone,
					message,
				})
				.then((res) => {
					this.setState({ sent: true });
				})
				.catch((err) =>
					console.error("Wysyłanie nie powiodło się. Error: ", err)
				);
			this.setState({ button_class: "sent", ButtonText: "Wysłano" });
		} else {
			this.setState({ button_class: "notsent", ButtonText: "Błąd" });
		}
	}
	handleUserInput(e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value }, () => {
			this.validateField(name, value);
		});
	}
	validateField(fieldName, value) {
		let nameValid = this.state.nameValid;
		let messageValid = this.state.messageValid;
		let emailValid = this.state.emailValid;
		let email2Valid = this.state.email2Valid;
		let phoneValid = this.state.phoneValid;
		switch (fieldName) {
			case "name":
				nameValid = value.length >= 3;
				break;
			case "message":
				messageValid = value.length >= 3;
				break;
			case "email":
				emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
				break;
			case "email2":
				email2Valid = value.length < 1;
				break;
			case "phone":
				phoneValid =
					value.match(/^\d+/) && value.length >= 9 && value.length <= 14;
				break;
			default:
				break;
		}
		if (!this.state.sent) {
			this.setState(
				{
					nameValid: nameValid,
					messageValid: messageValid,
					emailValid: emailValid,
					email2Valid: email2Valid,
					phoneValid: phoneValid,
				},
				this.validateForm
			);
		}
	}
	validateForm() {
		console.log(this.state);
		this.setState({
			formValid:
				this.state.nameValid &&
				this.state.messageValid &&
				this.state.emailValid &&
				this.state.email2Valid &&
				this.state.phoneValid,
		});
		if (this.state.formValid) {
			this.setState({
				button_class: "contact-submit cta",
				ButtonText: "Wyślij",
			});
		}
	}
	render() {
		return (
			<section id="scontact" className="contact">
				<div className="container contact-container">
					<Row>
						<h4
							data-aos="fade-up"
							data-aos-delay="400"
							className="contact-title">
							Skontaktuj się z nami!
						</h4>
					</Row>
					<Row>
						<Col
							s={12}
							m={6}
							data-aos="fade-right"
							data-aos-delay="200"
							className="form">
							<Row>
								<TextInput
									name="name"
									placeholder="Imię i nazwisko"
									validate
									s={6}
									value={this.state.name}
									onChange={(event) => this.handleUserInput(event)}
								/>
								<TextInput
									name="phone"
									placeholder="Numer telefonu"
									validate
									s={6}
									value={this.state.phone}
									onChange={(event) => this.handleUserInput(event)}
								/>
								<TextInput
									name="email"
									placeholder="Adres e-mail"
									validate
									type="email"
									s={12}
									value={this.state.email}
									onChange={(event) => this.handleUserInput(event)}
								/>
								<input
									name="email2"
									placeholder="Adres e-mail2"
									s={12}
									value={this.state.email2}
									style={{ display: "none" }}
									onChange={(event) => this.handleUserInput(event)}
								/>
								<Textarea
									name="message"
									placeholder="Treść wiadomości"
									validate
									type="textarea"
									s={12}
									value={this.state.message}
									onChange={(event) => this.handleUserInput(event)}
								/>
								<Button
									ref={this.divRef}
									id="button-submit"
									className={this.state.button_class}
									type="submit"
									s={2}
									onClick={(e) => this.submitHandler(e)}
									disabled={!this.state.formValid || this.state.sent}>
									{this.state.ButtonText}
								</Button>
							</Row>
						</Col>
						<Col
							s={12}
							m={6}
							data-aos="fade-left"
							data-aos-delay="200"
							className="info">
							<Row className="contact-title">
								<h5>Wydawnictwo Avalon</h5>
								<svg viewBox="0 0 108 108">
									<path
										style={{ fill: "#fff" }}
										d="M22.35,79.09A37.75,37.75,0,0,0,35,67c1.65-2.56,3.11-5.24,4.66-7.87C40.24,58.08,40.8,57,41.53,56a2.11,2.11,0,0,1,1.45-.8c6.37,0,12.74.16,19.1.21.89,0,1.22.44,1.57,1.13,2.17,4.34,4.39,8.67,6.54,13a2,2,0,0,0,1.92,1.3c1.15,0,2.3.08,3.44.2,4.71.5,8.37-1.64,11.7-4.61a4.94,4.94,0,0,0,.55-.57c0-.06,0-.16,0-.06-2.9.07-5.86.1-8.81.24a1.84,1.84,0,0,1-2-1.26c-5.85-12-11.77-24.05-17.61-36.1A2.53,2.53,0,0,0,56.57,27c-2.34.12-4.68,0-7,0-3.59.07-6,2.29-8.78,4.65h10.1c-.22.48-.38.86-.57,1.22C46.93,39,43.54,45,40.23,51.11a5.48,5.48,0,0,1-3,2.8,9.07,9.07,0,0,0-2.69,1.6h3.06C32.83,63.7,29.39,72.39,22.2,78.79a27.69,27.69,0,0,1-3-8c-1.83-8.1-1.82-16.32-1.37-24.54a65.65,65.65,0,0,1,1.69-11.5c1.88-7.94,7.06-12.82,14.89-15,7.34-2,14.85-2.06,22.38-2a71.26,71.26,0,0,1,15.26,1.6c9.61,2.22,15,8.31,17,17.83,1.51,7.44,1.5,15,1.09,22.45a77.7,77.7,0,0,1-1.73,12.71C86.3,82,80.08,87.34,70.53,89.05,63.3,90.35,56,90.26,48.71,90.13A53.46,53.46,0,0,1,34,88.19a19.22,19.22,0,0,1-11-7.88C22.81,79.92,22.59,79.5,22.35,79.09Z"
									/>
									<path
										style={{ fill: "#fff" }}
										d="M53.51,36,61.4,51.76H44.11Z"
									/>
								</svg>
							</Row>

							<p className="desc">
								Wydawnictwo Avalon powstało z pasji do historii i chęci jej
								popularyzowania. Publikacje naszej oficyny adresowane są do
								wszystkich miłośników Klio, zarówno tych zajmujących się nią
								zawodowo, jak i tych, dla których odkrywanie przeszłości jest
								czystą przyjemnością.
							</p>
							<dl>
								<dt>
									<Map size="15" /> ul. Żmujdzka 6 B, 31-426 Kraków
								</dt>
								<dt>
									<PhoneCall size="15" /> +48 577 000 186
								</dt>
								<dt>
									<Mail size="15" /> redakcja@wydawnictwoAVALON.pl
								</dt>
							</dl>
						</Col>
					</Row>
				</div>
			</section>
		);
	}
}

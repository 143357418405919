import React from "react";
import { Row, Col } from "react-materialize";
import Slider from "react-slick";
import symbone from "./img/symbone.jpg";
import wsymbone from "./img/symbone.webp";
import symbtwo from "./img/symbtwo.jpg";
import wsymbtwo from "./img/symbtwo.webp";
import wrzesone from "./img/wrzesone.jpg";
import wwrzesone from "./img/wrzesone.webp";
import wrzestwo from "./img/wrzestwo.jpg";
import wwrzestwo from "./img/wrzestwo.webp";
import uzbrone from "./img/uzbrone.jpg";
import wuzbrone from "./img/uzbrone.webp";
import uzbrtwo from "./img/uzbrtwo.jpg";
import wuzbrtwo from "./img/uzbrtwo.webp";
import zycieone from "./img/zycieone.jpg";
import wzycieone from "./img/zycieone.webp";
import zycietwo from "./img/zycietwo.jpg";
import wzycietwo from "./img/zycietwo.webp";

import czapki from "./img/czapki.jpg";
import wczapki from "./img/czapki.webp";
import kapelusze from "./img/kapelusze.jpg";
import wkapelusze from "./img/kapelusze.webp";
import peleryny from "./img/peleryny.jpg";
import wpeleryny from "./img/peleryny.webp";

export default class Book extends React.Component {
	render() {
		const settings = {
			adaptiveHeight: true,
			arrows: false,
			infinite: false,
			speed: 500,
			beforeChange: (currentSlide, nextSlide) => {
				const links = document.querySelectorAll(".frag-nav li");
				let mySlideNumber = nextSlide;
				for (let i = 0; i < links.length; i++) {
					links[i].classList.remove("frag-slide-active");
				}
				links[mySlideNumber].classList.add("frag-slide-active");
			}
		};
		return (
			<section id="sbook" className="">
				<div className="container">
					<Row>
						<h4 data-aos="fade-up" className="book-title first l">
							Symbolika
						</h4>
					</Row>
					<Row className="col-reverse">
						<Col s={12} l={6} data-aos="fade-right" data-aos-delay="350">
							<p>
								Pułki podhalańskie były formacjami, w których symbolice i mundurze nawiązano szeroko
								do góralskiego folkloru oraz jednego z najstarszych symboli kultury ogólnoludzkiej.
								Z regionalnych tradycji ludowych wywodzą się niektóre części podhalańskiego munduru:
								kapelusz i peleryna, a także zwyczaj ozdabiania nakryć głowy piórami. Z tej tradycji
								– chociaż nie tylko – wywodzi się też wspólny dla wszystkich formacji podhalańskich
								emblemat, tj. wpleciona w gałązkę jedliny swastyka.
							</p>
						</Col>
						<Col s={12} l={5} data-aos="fade-left" data-aos-delay="200">
							<img src={this.props.webp ? wsymbone : symbone} alt="Book" />
						</Col>
					</Row>
					<Row>
						<Col s={12} l={5} data-aos="fade-right" data-aos-delay="200">
							<img src={this.props.webp ? wsymbtwo : symbtwo} alt="Book" />
						</Col>
						<Col s={12} l={6} data-aos="fade-left" data-aos-delay="350">
							<p>
								Znak swastyki na polskim mundurze zarówno przedwojennym, jak i wojennym budzi
								dzisiaj zdziwienie i kontrowersje. Powszechnie uważa się bowiem, że swastyka to
								symbol hitlerowski. Oczywiście tak nie jest. Więcej informacji w rozdziale
								„Symbolika polskich wojsk górskich”.
							</p>
						</Col>
					</Row>

					<Row>
						<h4 data-aos="fade-up" className="book-title r">
							Umundurowanie
						</h4>
					</Row>
					<Row>
						<p data-aos="fade-up" className="book-desc">
							Do najbardziej strojnych i reprezentacyjnych mundurów wojskowych stosowanych w okresie
							międzywojennym w Wojsku Polskim należał mundur noszony przez żołnierzy pułków
							podhalańskich. Ubrani w kapelusze i peleryny żołnierze byli ozdobą każdej defilady.
							Reprezentacyjne przymioty munduru formacji podhalańskich sprawiły, że najwyższym
							dostojnikom państwowym podczas ich oficjalnych, a także innych wystąpień niemal zawsze
							towarzyszył oficer w mundurze podhalańskim. Przez długi okres adiutantem przybocznym
							prezydenta Ignacego Mościckiego był występujący w mundurze podhalańskim kpt. Józef
							Hartman. Najbardziej efektownym elementem mundurów podhalańskich był kapelusz. Został
							on wprowadzony w Wojsku Polskim przez gen. Andrzeja Galicę, który sięgnął do tradycji
							regionalnych, wzorując wojskowe nakrycie głowy na noszonym przez górali tatrzańskich
							kłobuku (Rozdział III Umundurowanie polskich formacji górskich).
						</p>
					</Row>
					<Row>
						<Col l={3} s={12}>
							<div className="frag-nav">
								<ul>
									<li
										data-aos="fade-right"
										data-aos-delay="250"
										onClick={e => this.slider.slickGoTo("0")}
										className="frag-slide-active frag-nava"
									>
										<div className="nav-num">01</div>
										<p className="">Kapelusze</p>
									</li>
									<li
										data-aos="fade-right"
										data-aos-delay="400"
										onClick={e => this.slider.slickGoTo("1")}
										className="frag-nava"
									>
										<div className="nav-num">02</div>
										<p className="">Peleryny</p>
									</li>
									<li
										data-aos="fade-right"
										data-aos-delay="550"
										onClick={e => this.slider.slickGoTo("2")}
										className="frag-nava"
									>
										<div className="nav-num">03</div>
										<p className="">Czapki</p>
									</li>
								</ul>
							</div>
						</Col>
						<Col l={9} s={12}>
							<div data-aos="fade-left" data-aos-delay="250" className="frag-slider">
								<Slider ref={slider => (this.slider = slider)} {...settings}>
									<div className="fslide">
										<img
											src={this.props.webp ? wkapelusze : kapelusze}
											alt="Kapelusze - Umundurowanie "
											style={{ filter: "none" }}
										/>
									</div>
									<div className="fslide">
										<img
											src={this.props.webp ? wpeleryny : peleryny}
											alt="Peleryny - Umundurowanie "
											style={{ filter: "none" }}
										/>
									</div>
									<div className="fslide">
										<img
											src={this.props.webp ? wczapki : czapki}
											alt="Czapki - Umundurowanie "
											style={{ filter: "none" }}
										/>
									</div>
								</Slider>
							</div>
						</Col>
					</Row>

					<Row>
						<h4 data-aos="fade-up" className="book-title l">
							Życie codzienne
						</h4>
					</Row>
					<Row>
						<Col s={12} l={5} data-aos="fade-right" data-aos-delay="200">
							<img src={this.props.webp ? wzycieone : zycieone} alt="Book" />
						</Col>
						<Col s={12} l={6} data-aos="fade-left" data-aos-delay="350">
							<p>
								W formacjach podhalańskich tradycje regionalne, wojskowe, a także ich odrębność w
								stosunku do innych formacji miały szczególne znaczenie w codziennym życiu
								żołnierskim. Czynniki te integrowały kadrę zawodową, zespalały żołnierzy służby
								zasadniczej, kształtowały patriotyzm pułkowy i dywizyjny.
							</p>
						</Col>
					</Row>
					<Row className="col-reverse">
						<Col s={12} l={6} data-aos="fade-right" data-aos-delay="350">
							<p>
								Gen. Andrzej Galica, tworząc jednostki górskie i wprowadzając dla służących w nich
								żołnierzy niespotykane gdzie indziej części mundurowania, symboliki i góralskich
								tradycji, kierował się nie tylko sentymentem do stroju góralskiego czy góralskich
								zwyczajów: <span style={{fontStyle: 'italic', fontWeight: '600'}}>"Na tych rodzimych cechach (góralskich) wytworzyłem w formacjach
								podhalańskich to, co się po francusku nazywa: esprit de corps. Takie rodzime cechy
								winno się w wojsku pielęgnować, bo to się w ciężkich chwilach w boju opłaca. Poza tym
								wytwarzają one kulturę nienarzuconą, opierającą się wszelakiej niwelacji, wytwarzają
								miłość rodzinnej ziemi, miłość Ojczyzny [...]"</span>
							</p>
						</Col>
						<Col s={12} l={5} data-aos="fade-left" data-aos-delay="200">
							<img src={this.props.webp ? wzycietwo : zycietwo} alt="Book" />
						</Col>
					</Row>

					<Row>
						<h4 data-aos="fade-up" className="book-title l">
							Uzbrojenie
						</h4>
					</Row>
					<Row>
						<Col s={12} l={5} data-aos="fade-right" data-aos-delay="200">
							<img src={this.props.webp ? wuzbrone : uzbrone} alt="Book" />
						</Col>
						<Col s={12} l={6} data-aos="fade-left" data-aos-delay="350">
							<p>
								W skład formacji górskich wszystkich armii wchodziły dwa podstawowe rodzaje wojsk:
								piechota górska i artyleria górska. O ile uzbrojenie piechoty górskiej, zwłaszcza
								tej z okresu międzywojennego, nie różni się prawie niczym od uzbrojenia zwykłej
								piechoty, to różnice między uzbrojeniem artylerii górskiej i artylerii zwykłej (np.
								lekkiej czy ciężkiej) są znaczne. Tworząc górskie formacje wojskowe, teoretycy
								wojskowi zdawali sobie sprawę z tego, że w górach może walczyć prawie każdy żołnierz
								piechoty, to jednak nie każdy rodzaj artylerii może być wykorzystany do walki
								górskiej.
							</p>
						</Col>
					</Row>
					<Row className="col-reverse">
						<Col s={12} l={6} data-aos="fade-right" data-aos-delay="350">
							<p>
								Artyleria górska jest bowiem specjalnym rodzajem artylerii. Jest ona używana – jak
								sama nazwa wskazuje – do działań w górach, a także w terenach mało przystępnych, o
								dużych spadach i przeważnie zalesionych. Dlatego też zwykła artyleria polowa lekka
								lub ciężka nie zawsze była w stanie udzielić wsparcia piechocie walczącej w trudnym
								terenie górskim ze względu na to, że nie wszędzie można ją było przetransportować,
								skoncentrować, nie zawsze mogła towarzyszyć maszerującej piechocie. Jednostki
								artylerii górskiej muszą być więc uzbrojone w innego rodzaju działa niż zwykłe
								formacje artylerii, np. lekkiej czy ciężkiej. (Rozdział V Uzbrojenie pułków
								podhalańskich)
							</p>
						</Col>
						<Col s={12} l={5} data-aos="fade-left" data-aos-delay="200">
							<img src={this.props.webp ? wuzbrtwo : uzbrtwo} alt="Book" />
						</Col>
					</Row>
					<Row>
						<h4 data-aos="fade-up" className="book-title l">
							Udział dywizji górskich w kampanii wrześniowej
						</h4>
					</Row>
					<Row>
						<Col s={12} l={5} data-aos="fade-right" data-aos-delay="200">
							<img src={this.props.webp ? wwrzestwo : wrzestwo} alt="Book" />
						</Col>
						<Col s={12} l={6} data-aos="fade-left" data-aos-delay="350">
							<p>
								W całodniowych walkach pozycje polskie pod Mogilanami zostały utrzymane. Niemiecka
								45. DP doznała znacznych strat, jej zmotoryzowany oddział rozpoznawczy stracił
								większość swych samochodów pancernych i czołgów. Straty poniosła również atakująca
								piechota niemiecka. Straty polskie były znacznie mniejsze: głównie od ognia
								artylerii nieprzyjacielskiej, która zwłaszcza wieczorem dość intensywnie
								ostrzeliwała pozycje polskiej piechoty i artylerii, np. w III dywizjonie 21. PAL 5
								września zginęło trzech żołnierzy, a dwóch zostało rannych.
							</p>
						</Col>
					</Row>
					<Row className="col-reverse">
						<Col s={12} l={6} data-aos="fade-right" data-aos-delay="350">
							<p>
								Bitwa pod Mogilanami została wygrana przez 21. DPG siłami dwóch jej pułków: 4. Pułku
								Strzelców Podhalańskich oraz 21. Pułku Artylerii Lekkiej. Żołnierze poznali
								skuteczność swego oręża, podniosło się ogromnie morale w pododdziałach.
								Przypuszczano, że dywizja zostanie na swych dotychczasowych pozycjach albo pójdzie
								do przodu, że losy wojny odwrócą się wreszcie – w oddziałach wiedziano już, że
								Anglia i Francja wypowiedziały wojnę Niemcom. Nic więc nie wskazywało na to, że
								Podhalanie – mimo że ich pozycji nie przełamano – będą musieli je opuścić. (Rozdział
								VI Udział dywizji górskich w kampanii wrześniowej 1939 roku)
							</p>
						</Col>
						<Col s={12} l={5} data-aos="fade-left" data-aos-delay="200">
							<img src={this.props.webp ? wwrzesone : wrzesone} alt="Book" />
						</Col>
					</Row>
				</div>
			</section>
		);
	}
}

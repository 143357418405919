import React from "react";
import { Row, Col, Button } from "react-materialize";
import { Parallax } from "react-parallax";
import ctabg from "./img/ctabg.jpg";
import wctabg from "./img/ctabg.webp";
import covera from "./img/offer_item.png";
import wcovera from "./img/offer_item.webp";

export default class Cta extends React.Component {
  render() {
    return (
      <div className="cta-wrap">
        <section id="scta" className="cta">
          <Parallax
            blur={{ min: -5, max: 5 }}
            bgImage={this.props.webp ? wctabg : ctabg}
            bgImageAlt="Zamów teraz"
            strength={-200}
          >
            <div style={{ height: "70vh", width: "100%" }} />
          </Parallax>
        </section>
        <section className="cta-after">
          <Row>
            <Col data-aos="fade-right" data-aos-delay="100" m={6} s={12}>
              <picture>
                <source srcSet={wcovera} />
                <source srcSet={covera} />
                <img
                  src={wcovera}
                  alt="Strzelcy Podhalańscy okładka przód"
                ></img>
              </picture>
            </Col>
            <Col m={6} s={12}>
              <h4 data-aos="fade-up" data-aos-delay="200" className="cta-title">
                Wojska Górskie II Rzeczypospolitej 1918–1939
              </h4>
              <p data-aos="fade-up" data-aos-delay="400" className="cta-desc">
                Najnowsza publikacja traktująca o formacjach Strzelców
                Podhalańskich.
              </p>
              <span data-aos="fade-up" data-aos-delay="1000">
              <Button >
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    alt="Zamów teraz"
                    href="https://wydawnictwoavalon.pl/produkt/wojska-gorskie-ii-rzeczypospolitej-1918-1939/"
                  >
                    Zamów teraz
                  </a>
              </Button>
              </span>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.scss";
import Nawigacja from "./Nawigacja";
import Hero from "./Hero";
import Quote from "./Quote";
import { Galeria } from "./Galeria";
import Book from "./Book";
import AboutA from "./AboutA";
import Cta from "./Cta";
import Kontakt from "./Kontakt";
import Footer from "./Footer";
const Main = props => (
  <>
    <Nawigacja />
    <div id="content" data-aos="fade-in" data-aos-duration="1000">
      <Hero webp={props.webp} />
      <div className="bg-wrap">
        <Book />
        <Quote />
        <AboutA webp={props.webp} />
        <Galeria webp={props.webp} />
      </div>
      <Cta webp={props.webp} />
      <Kontakt />
      <Footer />
    </div>
  </>
);
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webp: true
    };
  }
  componentDidMount() {
    AOS.init({
      duration: 1000,
      animatedClassName: "animated",
      once: true
    });
    document.getElementsByTagName("html")[0].classList.contains("webp")
      ? this.setState({ webp: true })
      : this.setState({ webp: false });
  }
  render() {
    return (
      <Router basename={"/"}>
        <Main webp={this.state.webp} />
      </Router>
    );
  }
}
export default App;

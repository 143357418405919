import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import M from "materialize-css";

export default class Footer extends React.Component {
  componentDidMount() {
    document.addEventListener("DOMContentLoaded", function() {
      var elems = document.querySelectorAll(".tooltipped");
      let options = { position: "top" };
      M.Tooltip.init(elems, options);
    });
  }
  render() {
    return (
      <section id="sfooter" className="footer">
        <div className="container footer-container">
          <div className="col s12">
            <span className="footer-copy">
              ©2020{" "}
              <a
                href="https://wydawnictwoavalon.pl"
                rel="noopener noreferrer"
                target="_blank"
                className="tooltipped"
                data-tooltip="Nasze wydawnictwo"
              >
                Wydawnictwo Avalon
              </a>
            </span>
            <span className="footer-social">
              <a
                className="tooltipped social facebook"
                data-tooltip="Nasz fanpage"
                href="https://www.facebook.com/Wydawnictwo-Avalon-674997069531772/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                className="tooltipped social instagram"
                data-tooltip="Nasz instagram"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </span>
          </div>
        </div>
      </section>
    );
  }
}

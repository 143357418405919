import React from "react";
import { Col, Button } from "react-materialize";
import { ResponsiveImage, ResponsiveImageSize } from "react-responsive-image";
import hero_s from "./img/hero_s.jpg";
import hero_m from "./img/hero_m.jpg";
import hero_l from "./img/hero_l.jpg";
import whero_s from "./img/hero_s.webp";
import whero_m from "./img/hero_m.webp";
import whero_l from "./img/hero_l.webp";
export default class Hero extends React.Component {
  render() {
    return (
      <section id="shero" className="hero">
        <div className="container">
          <div className="row">
            <Col s={12}>
              <h2
                data-aos="fade-up"
                data-aos-delay="250"
                className="hero-title"
              >
                Wojska Górskie
                <span className="hero-stitle">
                  II Rzeczypospolitej 1918-1939
                </span>
              </h2>
              <p data-aos="fade-up" data-aos-delay="500" className="hero-desc">
                pierwsza <b>pełna</b> monografia formacji podhalańskich
                autorstwa Wojciecha Mosia.
              </p>
              <div data-aos="fade-up" data-aos-delay="700">
                <Button className="btn-hero">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    alt="Zamów teraz"
                    href="https://wydawnictwoavalon.pl/produkt/wojska-gorskie-ii-rzeczypospolitej-1918-1939/"
                  >
                    Zamów teraz
                  </a>
                </Button>
              </div>
            </Col>
            <ResponsiveImage alt="Strzelcy Podhalańscy">
              <ResponsiveImageSize
                default
                minWidth={0}
                path={this.props.webp ? whero_s : hero_s}
              />
              <ResponsiveImageSize
                minWidth={721}
                path={this.props.webp ? whero_m : hero_m}
              />
              <ResponsiveImageSize
                minWidth={991}
                path={this.props.webp ? whero_l : hero_l}
              />
            </ResponsiveImage>
          </div>
        </div>
        <div data-aos="slide-right" data-aos-delay="1000" className="hero-fb">
          <p>
            inne informacje wydawnicze na naszym{" "}
            <a
              className="white-text"
              href="https://www.facebook.com/Wydawnictwo-Avalon-674997069531772/"
              rel="noopener noreferrer"
              target="_blank"
            >
              fanpagu
            </a>
          </p>
        </div>
      </section>
    );
  }
}
